let configJson:any = "";

if (process.env.NODE_ENV !== 'production') {
  configJson = require("./.local-config.json");
  
} else {
  configJson = require("./config.json");
}

export function getConfig() {

  return {
    auth0Auth: true,
    authority: "https://rolling.auth0.com",
    clientId: "8H9dobZuRhjRdP9NR44GjntfrfsuSxqX",
    scopesSupported: "openid profile email api offline_access email_verified",
    apiOrigin: "https://netbird.portallite.net",
    grpcApiOrigin: "https://netbird.portallite.net",
    latestVersion: "1",
    audience: "https://rolling.auth0.com/api/v2/"
  };
}
